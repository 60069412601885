// Syntax for themes light
.light {
  @import "syntax-light";
}
// Syntax for themes dark
.dark {
  @import "syntax-dark";
}
// Syntax for gist dark
@import "syntax-gist-dark";

// Themes is in assets/css/tailwind.css
