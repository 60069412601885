.cc-window.cc-banner {
  .cc-btn {
    color: $global-font-color;

    &:hover,
    &:focus {
      background-color: $cookie-background-color;
    }
  }
}
