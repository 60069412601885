/* Opt-in the whole page to interpolate sizes to/from keywords */
// https://developer.chrome.com/docs/css-ui/animate-to-height-auto
:root {
  interpolate-size: allow-keywords;
}

html {
  font-family: $global-font-family;
  font-weight: $global-font-weight;
  font-display: swap;
  font-size: $global-font-size;
  line-height: $global-line-height;
  width: 100%;
  scroll-behavior: smooth;
  overflow: overlay;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

::selection {
  background-color: $selection-color;
}

body {
  background-color: $global-background-color;
  color: $global-font-color;
  @include overflow-wrap(break-word);
  scrollbar-color: auto;
}

@include ms;
@include link(true, true);

@import "../_partial/mask";
@import "../_partial/icon";
@import "../_partial/details";
@import "../_partial/cookieconsent";

img {
  object-fit: cover;
}
