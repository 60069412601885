svg.icon {
  display: inline-block;
  width: 1.25em;
  height: 1em;
  text-align: center;

  path {
    fill: currentColor;
  }
}

img.emoji {
  height: 1em;
  width: 1em;
  margin: 0 0.05em 0 0.1em;
  vertical-align: -0.1em;
  display: inline-block !important;
}

svg.icon {
  display: inline-block;
  height: 1em;
  width: 1.25em;
  vertical-align: -0.125em;
  color: $global-font-color default;
}

svg.icon > path {
  fill: currentColor;
}
