.related-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  width: 100%;
  height: 280px;

  .related-item-container {
    flex-basis: 350px;
    flex-grow: 1;
    flex-shrink: 1;
    height: 270px;
    min-width: 300px;
    margin-right: 20px;
    background: $related-background-color;
    position: relative;
  }

  .related-image {
    @include transition(transform 0.4s ease);

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    &:hover {
      @include transform(scale(1.01));
    }
  }

  .related-title {
    position: absolute;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    top: 210px;
    width: 95%;
    margin: 10px;
    font-size: 1.25rem;
    line-height: 140%;
  }

  .related-title a {
    color: $related-color;

    &:hover {
      color: $related-hover-color;
    }
  }
}
