@media only screen and (max-width: 1440px) {
  .page {
    width: 56%;
  }
  #toc-auto {
    left: 78%;
    width: 22%;
  }
}

@media only screen and (max-width: 1200px) {
  .page {
    width: 52%;
  }

  #toc-auto {
    left: 76%;
    width: 24%;
  }

  #header-desktop .header-wrapper {
    padding-right: 1rem;
  }

  .search-dropdown.desktop {
    right: 1rem;
  }
}

@media only screen and (max-width: 1000px) {
  #toc-auto {
    display: none;
  }

  #toc-static {
    display: block;
  }

  .page {
    width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  #header-desktop .header-wrapper {
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 680px) {
  #header-desktop {
    display: none;
  }

  #header-mobile {
    display: block;
  }

  body.blur {
    overflow: hidden;
  }

  .page {
    width: 100% !important;
    margin-left: auto !important;
    padding-top: $header-height;

    .categories-card,
    .author-card,
    .series-card {
      .card-item {
        width: 100%;
      }
    }
  }

  .copyright {
    .copyright-line {
      .icp-splitter {
        display: none;
      }
      .icp-br {
        display: block;
      }
    }
  }
}
