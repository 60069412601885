.details {
  .details-summary {
    &:hover {
      cursor: pointer;
    }
  }

  .details-icon > svg {
    color: $global-font-secondary-color;
    @include transition(transform 0.2s ease);
  }

  .details-content {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.5s ease-out;
  }

  &.open {
    .details-icon > svg {
      @include transform(rotate(90deg));
    }

    .details-content {
      max-height: none;
    }
  }
}
